import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";

const CardLayout = ({ cusCount, profCount }) => {
  const redirect = (e) => {
    console.log(e);
  };
  return (
    <>
      <div className="kpi-container">
        <Card
          onClick={redirect}
          title="Professionals"
          count={profCount}
          subtitle=""
        />
        <Card
          onClick={redirect}
          title="Consumers"
          count={cusCount}
          subtitle=""
        />
      </div>
    </>
  );
};

export default CardLayout;
