import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/consumer_filter_reducer";

import {
  LOAD_CONSUMERS,
  FILTER_CONS,
  UPDATE_CONS_FILTER,
  CLEAR_CONS_FILTER,
  UNAUTHORIZED,
} from "../actions";

import { useConsumerContext } from "./consumer_context";

const initialState = {
  filtered_consumers: [],
  all_consumers: [],
  consumer_error: false,
  error_code: 200,
  filters: {
    text: "",
    category: "all",
  },
};

const ConsumerFilterContext = React.createContext();

export const ConsumerFilterProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const {
    consumers,
    fetchConsumers,
    consumer_error,
    error_code,
  } = useConsumerContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (consumer_error && error_code !== 200) {
      dispatch({
        type: UNAUTHORIZED,
        payload: { error_code: error_code, consumer_error: consumer_error },
      });
    }
  }, [consumer_error, error_code]);

  useEffect(() => {
    dispatch({
      type: LOAD_CONSUMERS,
      payload: {
        data: consumers,
        error_code: error_code,
        consumer_error: consumer_error,
      },
    });
  }, [consumers]);

  useEffect(() => {
    dispatch({ type: FILTER_CONS });
  }, [consumers, state.filters]);

  const updateConsFilters = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_CONS_FILTER, payload: { name, value } });
  };

  const reloadData = () => {
    // dispatch({type: LOAD_PROFESSIONALS, payload: professionals})
    fetchConsumers(`${restUrl}/consumer`);
  };

  const clearConsFilters = () => {
    dispatch({ type: CLEAR_CONS_FILTER });
  };

  return (
    <ConsumerFilterContext.Provider
      value={{ ...state, updateConsFilters, clearConsFilters, reloadData }}
    >
      {children}
    </ConsumerFilterContext.Provider>
  );
};

// make sure use this
export const useConsumerFilterContext = () => {
  return useContext(ConsumerFilterContext);
};
