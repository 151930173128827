import {
  GET_PROF_BEGIN,
  GET_PROF_ERROR,
  GET_PROF_SUCCESS,
  GET_PROF_EMAIL_SUCCESS,
  GET_PROF_EMAIL_ERROR,
} from "../actions";

const professional_reducer = (state, action) => {
  if (action.type === GET_PROF_BEGIN) {
    return { ...state, prof_loading: true };
  } else if (action.type === GET_PROF_SUCCESS) {
    return {
      ...state,
      prof_loading: false,
      professionals: action.payload.data,
      error_code: 200,
      prof_error: false,
    };
  } else if (action.type === GET_PROF_ERROR) {
    const error_code = action.payload.error_code;
    return {
      ...state,
      prof_loading: false,
      prof_error: true,
      error_code: error_code,
    };
  } else if (action.type === GET_PROF_EMAIL_SUCCESS) {
    return {
      ...state,
      prof_loading: false,
      proEmails: action.payload,
      error_code: 200,
      prof_error: false,
    };
  } else if (action.type === GET_PROF_EMAIL_ERROR) {
    return { ...state, prof_loading: false, prof_error: true };
  }
  //return state
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default professional_reducer;
