import axios from "axios";
import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/professional_filter_reducer";

import {
  FILTER_PROF,
  UPDATE_PROF_FILTER,
  CLEAR_PROF_FILTER,
  LOAD_PROFESSIONALS,
  UPDATE_PRODUCT_FILTER,
  LOAD_PRO_EMAILS,
  UNAUTHORIZED,
} from "../actions";

import { useProfessionalContext } from "./professional_context";

const initialState = {
  prof_loading: false,
  prof_error: false,
  error_code: 200,
  filtered_professionals: [],
  all_professionals: [],
  prof_emails: [],
  filters: {
    text: "",
    category: "all",
    filterproduct: "lauditor",
  },
};

const ProfFilterContext = React.createContext();

export const ProfFilterProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const bearer_token = sessionStorage.getItem("latoken");
  const {
    professionals,
    fetchProfessional,
    error_code,
    prof_error,
    proEmails,
    fetchProEmails,
  } = useProfessionalContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  //   console.log(`payload.error_code = ${error_code}`);

  useEffect(() => {
    if (prof_error && error_code !== 200) {
      dispatch({
        type: UNAUTHORIZED,
        payload: { error_code: error_code, prof_error: prof_error },
      });
    }
  }, [prof_error, error_code]);

  useEffect(() => {
    if (error_code === 200) {
      dispatch({
        type: LOAD_PROFESSIONALS,
        payload: {
          data: professionals,
          error_code: error_code,
          prof_error: false,
        },
      });
    }
  }, [professionals]);

  useEffect(() => {
    dispatch({ type: LOAD_PRO_EMAILS, payload: proEmails });
  }, [proEmails]);

  useEffect(() => {
    dispatch({
      type: FILTER_PROF,
      payload: { error_code: error_code, prof_error: prof_error },
    });
  }, [professionals, state.filters]);

  const reloadData = (product) => {
    // dispatch({type: LOAD_PROFESSIONALS, payload: professionals})
    let prodplan = "lauditor";
    if (state) {
      prodplan = state.filters.filterproduct;
    } else {
      prodplan = product;
    }
    fetchProfessional(`${restUrl}`, prodplan);
    fetchProEmails(`${restUrl}`, prodplan);
  };

  const updateProfFilters = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_PROF_FILTER, payload: { name, value } });
  };

  const changeProduct = (e) => {
    const value = e.target.value;
    fetchProfessional(`${restUrl}`, value);
    fetchProEmails(`${restUrl}`, value);
    dispatch({ type: UPDATE_PRODUCT_FILTER, payload: value });
  };

  const clearProfFilters = () => {
    dispatch({ type: CLEAR_PROF_FILTER });
  };

  return (
    <ProfFilterContext.Provider
      value={{
        ...state,
        updateProfFilters,
        clearProfFilters,
        reloadData,
        changeProduct,
      }}
    >
      {children}
    </ProfFilterContext.Provider>
  );
};

// make sure use this
export const useProfFilterContext = () => {
  return useContext(ProfFilterContext);
};
