import React from 'react'

const ConsumerRow = ({first_name, last_name, email, phone, joined, lastlogin}) => {
    //console.log(consumer)
  return (
    <tr>
        <td>
            {first_name}
        </td>
        <td>
            {last_name}
        </td>
        <td>
            {email}
        </td>
    </tr>
  )
}

export default ConsumerRow