import {
    SAVE_SUCCESS,
    UPDATE_PROF_VALUE,
    SAVE_PROF_BEGIN,
    SAVE_PROF_ERROR,
    RESET_ADD_PROF_STATE,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_ERROR
} from '../actions'

const add_prof_reducer = (state, action) => {
    if (action.type === SAVE_SUCCESS) {
        const status = action.payload
        if (status.error) {
            return {...state, savedStatus : true, prof_loading : false, prof_error: true, message: status.msg}
        } else {
            return {...state, 
                savedStatus : true, prof_loading : false, 
                prof_error: false, message: status.msg,
                fname: '',email: '',phone:'',country:'',cperson:'',product:''}
        }
    } else if (action.type === UPDATE_PROF_VALUE){
        const {name, value} = action.payload
        return {...state,[name]:value, savedStatus : false, prof_loading : false,prof_error: false}
    } else if (action.type === SAVE_PROF_BEGIN) {
        return {...state, savedStatus: false, prof_loading: true, prof_error: false}
    } else if (action.type === SAVE_PROF_ERROR) {
        return {...state, savedStatus: false, prof_loading: false, prof_error: true}
    } else if (action.type === RESET_ADD_PROF_STATE) {
        const {savedStatus, prof_error} = action.payload
        return {...state, savedStatus: savedStatus, prof_error: prof_error}
    } else if (action.type === GET_COUNTRIES_SUCCESS) {
        return {...state, countries: action.payload}
    } else if (action.type === GET_COUNTRIES_ERROR) {
        //need to handle more code here for errors, possibly send error messages
        return {...state}
    } 
    throw new Error(`No Matching "${action.type}" - action type`)
}

export default add_prof_reducer