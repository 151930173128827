import axios from "axios";
import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/lead_filter_reducer";

import {
  FILTER_LEAD,
  CLEAR_LEAD_FILTER,
  LOAD_LEADS,
  UPDATE_LEAD_FILTER,
  UPDATE_PRODUCT_FILTER,
} from "../actions";

import { useLeadContext } from "./lead_context";

const initialState = {
  lead_loading: false,
  lead_error: false,
  error_code: 200,
  filtered_leads: [],
  all_leads: [],
  filters: {
    text: "",
    category: "all",
    filterproduct: "lauditor",
  },
};

const LeadFilterContext = React.createContext();

export const LeadFIlterProvider = ({ children }) => {
  const restUrl = process.env.REACY_APP_LEAD_REST_ENDPOINT;
  const { leads, fetchLead, error_code, lead_error } = useLeadContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (error_code === 200) {
      dispatch({
        type: LOAD_LEADS,
        payload: {
          data: leads,
          error_code: error_code,
          lead_error: false,
        },
      });
    }
  }, [leads]);

  useEffect(() => {
    dispatch({
      type: FILTER_LEAD,
      payload: { error_code: error_code, lead_error: lead_error },
    });
  }, [leads, state.filters]);

  const updateLeadFilters = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_LEAD_FILTER, payload: { name, value } });
  };

  const changeProduct = (e) => {
    const value = e.target.value;
    fetchLead(`${restUrl}`, value);
    dispatch({ type: UPDATE_PRODUCT_FILTER, payload: value });
  };

  const clearLeadFilters = () => {
    dispatch({ type: CLEAR_LEAD_FILTER });
  };

  const reloadData = (product) => {
    let prodplan = "lauditor";
    if (state) {
      prodplan = state.filters.filterproduct;
    } else {
      prodplan = product;
    }
    fetchLead(`${restUrl}`, prodplan);
  };

  return (
    <LeadFilterContext.Provider
      value={{
        ...state,
        updateLeadFilters,
        clearLeadFilters,
        changeProduct,
        reloadData,
      }}
    >
      {children}
    </LeadFilterContext.Provider>
  );
};

export const useLeadFilterContext = () => {
  return useContext(LeadFilterContext);
};
