import React from "react";
import { Link } from "react-router-dom";

const Card = ({ title, count, subtitle }) => {
  return (
    <div className="kpi-card">
      <div className="kpi-card-gradient">
        <div className="kpi-title">{title}</div>
        <div className="kpi-value">{count}</div>
        <div className="kpi-description">{subtitle}</div>
      </div>
    </div>
  );
};

export default Card;
