import React from 'react'
import { useConsumerFilterContext } from '../context/consfilter_context'
import { FaSearch, FaFileCsv} from 'react-icons/fa'
import { CSVLink, CSVDownload } from "react-csv";

const Consfilter = () => {
  const {
    filters: {
        text, category
    }, 
    updateConsFilters, 
    clearConsFilters,
    filtered_consumers
  } = useConsumerFilterContext()

  return (
    <div className='float-right'>
        <form  onSubmit={(e)=>{e.preventDefault()}}>
            {/* Search Input */}
            <div className="form-control">
                <CSVLink data={filtered_consumers} filename={"consumers.csv"}><FaFileCsv/></CSVLink>&nbsp;&nbsp;
                <FaSearch/>&nbsp;&nbsp;
                <select className='search-input' name="category" id="category" value={category} onChange={updateConsFilters} >
                    <option value="all">all</option>
                    <option value="fname">First Name</option>
                    <option value="lname">Last Name</option>
                    <option value="email">Email</option>
                </select>
                <input type="text" 
                name="text" id="text" 
                placeholder='search' 
                className='search-input' 
                value={text} 
                onChange={updateConsFilters} />
                <button className='btn-small float-right hot' onClick={clearConsFilters}>clear filter</button>
            </div>
        </form>
        <div className='float-right'>Search found {filtered_consumers.length} rows</div>
    </div>
  )
}

export default Consfilter