import React, { useEffect } from "react";
import { useProfFilterContext } from "../context/prof_filter_context";
import { useLoginContext } from "../context/login_context";
import Error from "./Error";
import Loading from "./Loading";
import ProfessionalRow from "./ProfessionalRow";
import TopNav from "./TopNav";
import Proffilter from "./Proffilter";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Professional = () => {
  const navigate = useNavigate();
  const notify = (message) => toast.success(message);
  const location = useLocation();
  //the magical line for showing messages
  const { refreshData, showMessage, message, plan } = location.state;
  //console.log('professional state', state)
  const {
    prof_loading: loading,
    prof_error: error,
    error_code: error_code,
    filtered_professionals: filtered_professionals,
    prof_emails: prof_emails,
    reloadData,
  } = useProfFilterContext();

  useEffect(() => {
    if (error && error_code !== 200) {
      // sessionStorage.setItem("latoken", "");
      navigate("/login");
    }
  }, [error_code, error]);

  //Reload Fresh Data calling Rest API when fromSource is True
  useEffect(() => {
    // console.log(fromSource)
    if (refreshData) {
      //console.log("reloading data")
      reloadData(plan ? plan.product : plan);
    }
  }, [refreshData]);

  //call Success Message
  useEffect(() => {
    if (showMessage) {
      notify(message);
    }
  }, [showMessage]);

  //Get logged in User details
  const { loggedIn, user } = useLoginContext();

  // console.log(filtered_consumers)
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />
      <div className="section section-center">
        <ToastContainer hideProgressBar={true} theme="colored" />
        <div className="title">Professionals</div>
        <Proffilter />
        <table>
          <thead>
            <tr>
              <th>Firm Name</th>
              <th>Contact Person</th>
              <th>Email</th>
              <th>Product</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {filtered_professionals.map((professional) => {
              return (
                <ProfessionalRow key={professional.id} {...professional} />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Professional;
