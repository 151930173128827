import React from "react";
const LeadRow = ({ _id, firmname, person, email, phone, plan, createdAt }) => {
  //console.log(consumer)
  return (
    <tr key={_id}>
      <td>{firmname}</td>
      <td>{person}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{createdAt}</td>
      <td>{plan}</td>
    </tr>
  );
};

export default LeadRow;
