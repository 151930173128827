import { GET_CONS_BEGIN, GET_CONS_ERROR, GET_CONS_SUCCESS } from "../actions";

const consumer_reducer = (state, action) => {
  if (action.type === GET_CONS_BEGIN) {
    return { ...state, consumer_loading: true };
  } else if (action.type === GET_CONS_SUCCESS) {
    return {
      ...state,
      consumer_loading: false,
      consumers: action.payload.data,
      error_code: 200,
      consumer_error: false,
    };
  } else if (action.type === GET_CONS_ERROR) {
    const error_code = action.payload.error_code;
    return {
      ...state,
      consumer_loading: false,
      consumer_error: true,
      error_code: error_code,
    };
  }
  //return state;
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default consumer_reducer;
