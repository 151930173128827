export const GET_CONS_BEGIN = "GET_CONS_BEGIN";
export const GET_CONS_SUCCESS = "GET_CONS_SUCCESS";
export const GET_CONS_ERROR = "GET_CONS_ERROR";
export const LOAD_CONS = "LOAD_CONS";

export const GET_PROF_BEGIN = "GET_PROF_BEGIN";
export const GET_PROF_SUCCESS = "GET_PROF_SUCCESS";
export const GET_PROF_ERROR = "GET_PROF_ERROR";
export const LOAD_PROF = "LOAD_PROF";
export const RESET_ADD_PROF_STATE = "RESET_ADD_PROF_STATE";
export const GET_PROF_EMAIL_SUCCESS = "GET_PROF_EMAIL_SUCCESS";
export const GET_PROF_EMAIL_ERROR = "GET_PROF_EMAIL_ERROR";

export const FILTER_CONS = "FILTER_CONS";
export const CLEAR_CONS_FILTER = "CLEAR_CONS_FILTER";
export const UPDATE_CONS_FILTER = "UPDATE_CONS_FILTER";
export const LOAD_CONSUMERS = "LOAD_CONSUMERS";

export const FILTER_PROF = "FILTER_PROF";
export const CLEAR_PROF_FILTER = "CLEAR_PROF_FILTER";
export const UPDATE_PROF_FILTER = "UPDATE_PROF_FILTER";
export const LOAD_PROFESSIONALS = "LOAD_PROFESSIONALS";
export const LOAD_PRO_EMAILS = "LOAD_PRO_EMAILS";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";

export const GET_LEAD_BEGIN = "GET_LEAD_BEGIN";
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
export const GET_LEAD_ERROR = "GET_LEAD_ERROR";
export const FILTER_LEAD = "FILTER_LEAD";
export const CLEAR_LEAD_FILTER = "CLEAR_LEAD_FILTER";
export const UPDATE_LEAD_FILTER = "UPDATE_LEAD_FILTER";
export const LOAD_LEADS = "LOAD_LEADS";

export const RESET_PROD_STATE = "RESET_PROD_STATE";
export const MODIFY_PRODUCT = "MODIFY_PRODUCT";
export const UPDATE_PROF_ID = "UPDATE_PROF_ID";
export const GET_PROF_DETAIL_BEGIN = "GET_PROF_DETAIL_BEGIN";
export const GET_PROF_DETAIL_SUCCESS = "GET_PROF_DETAIL_SUCCESS";
export const GET_PROF_DETAIL_ERROR = "GET_PROF_DETAIL_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const UPDATE_PROF_VALUE = "UPDATE_PROF_VALUE";
export const SAVE_PROF_BEGIN = "SAVE_PROF_BEGIN";
export const SAVE_PROF_ERROR = "SAVE_PROF_ERROR";

export const UPDATE_LOGIN_VALUE = "UPDATE_LOGIN_VALUE";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GET_PROD_SUCCESS = "GET_PROD_SUCCESS";
export const GET_PROD_ERROR = "GET_PROD_ERROR";

export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_PROFCOUNT_SUCCESS = "GET_PROFCOUNT_SUCCESS";
export const GET_PROFCOUNT_ERROR = "GET_PROFCOUNT_ERROR";

export const GET_CONSCOUNT_SUCCESS = "GET_CONSCOUNT_SUCCESS";
export const GET_CONSCOUNT_ERROR = "GET_CONSCOUNT_ERROR";

export const LOGOUT = "LOGOUT";

export const UNAUTHORIZED = "UNAUTHORIZED";
