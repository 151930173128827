import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { ConsumerProvider } from "./context/consumer_context";
import { ConsumerFilterProvider } from "./context/consfilter_context";

import "./index.css";
import { LoginProvider } from "./context/login_context";
import { ProfessionalProvider } from "./context/professional_context";
import { ProfFilterProvider } from "./context/prof_filter_context";
import { ModifyProdProvider } from "./context/modifyprod_context";
import { AddProfProvider } from "./context/addprof_context";
import { LeadFIlterProvider } from "./context/lead_filter_context";
import { LeadProvider } from "./context/lead_context";
import { GlobalProvider } from "./context/global_context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LoginProvider>
    <GlobalProvider>
      <ProfessionalProvider>
        <ModifyProdProvider>
          <ProfFilterProvider>
            <ConsumerProvider>
              <ConsumerFilterProvider>
                <AddProfProvider>
                  <LeadProvider>
                    <LeadFIlterProvider>
                      <App />
                    </LeadFIlterProvider>
                  </LeadProvider>
                </AddProfProvider>
              </ConsumerFilterProvider>
            </ConsumerProvider>
          </ProfFilterProvider>
        </ModifyProdProvider>
      </ProfessionalProvider>
    </GlobalProvider>
  </LoginProvider>
);
