import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/professional_reducer";

import {
  GET_PROF_BEGIN,
  GET_PROF_ERROR,
  GET_PROF_SUCCESS,
  GET_PROF_EMAIL_SUCCESS,
  GET_PROF_EMAIL_ERROR,
} from "../actions";

const ProfessionalContext = React.createContext();

const initialState = {
  prof_loading: false,
  prof_error: false,
  error_code: 200,
  professionals: [],
  proEmails: [],
  product: "lauditor",
};

export const ProfessionalProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  // const rwUrl = 'https://dev.adminapi.regswatch.com';
  const rwUrl = process.env.REACT_APP_REST_ENDPOINT_REGSWATCH;
  console.log("****"+rwUrl)
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchProfessional = async (url, product) => {
    const bearer_token = sessionStorage.getItem("latoken");
    dispatch({ type: GET_PROF_BEGIN });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      let prodUrl = "";
      if (product !== "" && product!=="regswatch") {
        console.log(product)
        prodUrl = `${restUrl}/professional/search/${product}`;
      }
      else if(product === "regswatch"){
        prodUrl = `${rwUrl}/professional/search/${product}`
        console.log(prodUrl)
      }
       else {
        prodUrl = `${restUrl}/professional`;
      }
      const response = await axios.get(prodUrl, config);
      if (response.status !== undefined) {
        if (response.status === 200) {
          const professionals = await response.data;
          dispatch({ type: GET_PROF_SUCCESS, payload: professionals });
          // dispatch({
          //   type: GET_PROF_ERROR,
          //   payload: { error_code: 401, prof_error: true },
          // });
        } else {
          dispatch({
            type: GET_PROF_ERROR,
            payload: { error_code: response.status, prof_error: true },
          });
        }
      } else {
        dispatch({
          type: GET_PROF_ERROR,
          payload: { error_code: response.response.status, prof_error: true },
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: GET_PROF_ERROR,
          payload: { error_code: error.response.status, prof_error: true },
        });
      } else {
        //network error
        dispatch({
          type: GET_PROF_ERROR,
          payload: { error_code: 501, prof_error: true },
        });
      }
    }
  };

  const fetchProEmails = async (url, product) => {
    const bearer_token = sessionStorage.getItem("latoken");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      let prodUrl = "";
      if (product !== "" && product !=="regswatch") {
        prodUrl = `${restUrl}/professional/list/mail/${product}`;
      }else if(product === "regswatch"){
        prodUrl = `${rwUrl}/professional/list/mail/${product}`
        console.log(prodUrl)
      } 
      else {
        prodUrl = `${restUrl}/professional/list/mail`;
      }
      const response = await axios.get(prodUrl, config);
      console.log(response.data)
      const members = await response.data.data;

      let emailArray = [];
      members.map((user) => {
        const json = { name: user.name, email: user.email };
        emailArray.push(json);
        if (user.members.length >= 1) {
          user.members.map((member) => {
            const json = {
              firstname: member.first_name,
              lastname: member.last_name,
              email: member.email,
            };
            emailArray.push(json);
          });
        }
      });
      dispatch({ type: GET_PROF_EMAIL_SUCCESS, payload: emailArray });
    } catch (error) {
      dispatch({ type: GET_PROF_EMAIL_ERROR });
    }
  };

  useEffect(() => {
    fetchProfessional(restUrl, state.product);
    fetchProEmails(restUrl, state.product);
  }, []);

  return (
    <ProfessionalContext.Provider
      value={{ ...state, fetchProfessional, fetchProEmails }}
    >
      {children}
    </ProfessionalContext.Provider>
  );
};

export const useProfessionalContext = () => {
  return useContext(ProfessionalContext);
};
