import { GET_LEAD_BEGIN, GET_LEAD_ERROR, GET_LEAD_SUCCESS } from "../actions";

const lead_reducer = (state, action) => {
  if (action.type === GET_LEAD_BEGIN) {
    return { ...state, lead_loading: true };
  } else if (action.type === GET_LEAD_SUCCESS) {
    return {
      ...state,
      lead_loading: false,
      leads: action.payload.data,
      error_code: 200,
      lead_error: false,
    };
  } else if (action.type === GET_LEAD_ERROR) {
    const error_code = action.payload.error_code;
    return {
      ...state,
      lead_loading: false,
      lead_error: true,
      error_code: error_code,
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default lead_reducer;
