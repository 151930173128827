import {
  LOAD_PROFESSIONALS,
  FILTER_PROF,
  UPDATE_PROF_FILTER,
  CLEAR_PROF_FILTER,
  UPDATE_PRODUCT_FILTER,
  LOAD_PRO_EMAILS,
  UNAUTHORIZED,
} from "../actions";

const professional_filter_reducer = (state, action) => {
  // console.log(state);
  if (action.type === FILTER_PROF) {
    const { prof_error, error_code } = action.payload;
    //console.log(prof_error, error_code);
    const { all_professionals } = state;
    const { text, category } = state.filters;
    let tempProfessionals = [...all_professionals];
    if (text) {
      if (category === "email") {
        tempProfessionals = tempProfessionals.filter((professional) => {
          return professional.email.toLowerCase().startsWith(text);
        });
      } else if (category === "fname") {
        tempProfessionals = tempProfessionals.filter((professional) => {
          return professional.name.toLowerCase().startsWith(text);
        });
      } else if (category === "cperson") {
        tempProfessionals = tempProfessionals.filter((professional) => {
          return professional.contact_person.toLowerCase().startsWith(text);
        });
      } else if (category === "plan") {
        tempProfessionals = tempProfessionals.filter((professional) => {
          return professional.plan.toLowerCase().startsWith(text);
        });
      } else {
        tempProfessionals = tempProfessionals.filter((professional) => {
          return (
            professional.name.toLowerCase().startsWith(text) ||
            professional.contact_person.toLowerCase().startsWith(text) ||
            professional.email.toLowerCase().startsWith(text) ||
            professional.plan.toLowerCase().startsWith(text)
          );
        });
      }
    }
    return {
      ...state,
      filtered_professionals: tempProfessionals,
      prof_error: prof_error,
      error_code: error_code,
    }; //to be coded
  } else if (action.type === LOAD_PROFESSIONALS) {
    const payload = action.payload;
    return {
      ...state,
      all_professionals: payload.data,
      filtered_professionals: payload.data,
      filters: { ...state.filters },
      error_code: payload.error_code,
      prof_error: payload.prof_error,
    };
  } else if (action.type === LOAD_PRO_EMAILS) {
    return {
      ...state,
      prof_emails: action.payload,
      prof_error: false,
      error_code: 200,
    };
  } else if (action.type === UPDATE_PRODUCT_FILTER) {
    return {
      ...state,
      filters: {
        ...state.filters,
        filterproduct: action.payload,
        prof_error: false,
        error_code: 200,
      },
    };
  } else if (action.type === CLEAR_PROF_FILTER) {
    return {
      ...state,
      filters: { ...state.filters, text: "", category: "all" },
      prof_error: false,
      error_code: 200,
    }; //to be coded
  } else if (action.type === UPDATE_PROF_FILTER) {
    const { name, value } = action.payload;
    return {
      ...state,
      filters: { ...state, [name]: value },
      error_code: 200,
      prof_error: false,
    };
  } else if (action.type === UNAUTHORIZED) {
    // sessionStorage.setItem("latoken", "");
    return {
      ...state,
      error_code: action.payload.error_code,
      prof_error: action.payload.prof_error,
    };
  }
  //return state
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default professional_filter_reducer;
