import React, { useEffect, useContext, useReducer, useState } from "react";
import reducer from "../reducers/login_reducer";
import axios from "axios";

import { UPDATE_LOGIN_VALUE, LOGIN, LOGIN_ERROR } from "../actions";

const initialState = {
  uname: "",
  password: "",
  loggedIn: false,
  message: "",
  token: "",
};

const LoginContext = React.createContext();

export const LoginProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const [user, setuser] = useState(null);
  const [token, settoken] = useState("");
  const [message, setmessage] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   console.log(user);
  //   console.log(state);
  // }, [user]);

  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_LOGIN_VALUE, payload: { name, value } });
  };

  const login = async () => {
    try {
      const jsonData = { uid: state.uname, password: state.password };
      const response = await axios.post(`${restUrl}/apilogin`, jsonData);
      const loginResp = await response.data;
      // console.log(response);
      let respJSON = {};
      if (loginResp.error === false) {
        // console.log(`login token === ${loginResp.token}`);
        sessionStorage.setItem("latoken", loginResp.token);
        respJSON = { loggedIn: true, message: "", token: loginResp.token };
      } else {
        sessionStorage.setItem("latoken", "");
        respJSON = { loggedIn: false, message: loginResp.msg, token: "" };
      }
      dispatch({ type: LOGIN, payload: respJSON });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGIN_ERROR });
    }
  };

  return (
    <LoginContext.Provider
      value={{
        ...state,
        login,
        updateInputValue,
        user,
        setuser,
        settoken,
        setmessage,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
